<template lang="pug">
  .page-container.task-applications
    portal(to="toolbar-title")
      toolbar-title Applications task list

    .page-container__block
      search(:loading="loading" @change="loadList({})")

    .page-container__block
      jumbotron
        template(v-slot:content)
          app-select(
            :options="COURSE_TYPES_LIST"
            :value="$route.query[JUMBOTRON_FILTERS.COURSE_TYPE]"
            filter
            @input="setFilter(JUMBOTRON_FILTERS.COURSE_TYPE, $event)"
            placeholder="Course Type"
            slug-key='key'
            slug-value='name'
            show-close-btn
          )
          app-select(
            :options="EXAM_TYPES_LIST"
            :value="$route.query[JUMBOTRON_FILTERS.EXAM_TYPE]"
            filter
            @input="setFilter(JUMBOTRON_FILTERS.EXAM_TYPE, $event)"
            placeholder="Exam Type"
            slug-key='key'
            slug-value='name'
            show-close-btn
          )

          app-select(
            :options="$options.IS_REAPPLY_LIST"
            :value="$route.query[JUMBOTRON_FILTERS.IS_REAPPLY]"
            filter
            @input="setFilter(JUMBOTRON_FILTERS.IS_REAPPLY, $event)"
            placeholder="Reapply"
            slug-key='key'
            slug-value='name'
            show-close-btn
          )

          app-select(
            :options="$options.LICENSE_TYPE_LIST"
            :value="$route.query[JUMBOTRON_FILTERS.LICENSE_TYPE]"
            filter
            @input="setFilter(JUMBOTRON_FILTERS.LICENSE_TYPE, $event)"
            placeholder="License type"
            show-close-btn
          )

    .page-container__block.border-b
      .d-flex
        tabs(@change="changeTabs" :counts="counts")
      .d-flex
        pagination(:pagination="pagination" :loading="loading" @change="loadList({})")

    .page-container__list
      list(
        :headers="headers"
        :list="list"
        :loading="loading"
        :pagination="pagination"
        @loadMore="loadMore"
        @change:sorting="updateSorting"
        @needToUpdate="loadList({})"
      )
</template>

<script>
import api from '@/services/api/api-taskApllications'
import applicationService from './core/taskApplications-service'

import appSelect from '@/components/global/actions/BaseSelect.vue'

import PaginationModel from '@/models/paginationModel'
import paginationMixin from '@/mixins/pagination.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import featureFlagsMixin from '@/mixins/featureFlags.mixin'

import { TASK_APPLICATIONS_HEADERS, JUMBOTRON_FILTERS, TASK_APPLICATIONS_TAB } from './core/taskApplications-const'
import { COURSE_TYPES_LIST } from '@/util/const'
import {LICENSE_TYPE_OPTIONS} from "@/app/admin/modules/candidates/core/candidates-const";

export default {
  name: 'TaskApplicationsPage',

  mixins: [paginationMixin, errorsMixin, featureFlagsMixin],

  data: () => ({
    applicationService: new applicationService(api),
    pagination: {},
    list: [],
    loading: false,
    headers: TASK_APPLICATIONS_HEADERS,
    counts: {
      [TASK_APPLICATIONS_TAB.TASKS]: '...',
      [TASK_APPLICATIONS_TAB.WAIT]: '...',
      [TASK_APPLICATIONS_TAB.FUTURE]: '...',
      [TASK_APPLICATIONS_TAB.CANCELED]: '...',
      [TASK_APPLICATIONS_TAB.PAID]: '...'
    },
    COURSE_TYPES_LIST,
    EXAM_TYPES_LIST: [
      {key: 'false', name: 'Top'},
      {key: 'true', name: 'Mijn'}
    ],
    JUMBOTRON_FILTERS
  }),

  IS_REAPPLY_LIST: [
    {key: 'false', name: 'By new'},
    {key: 'true', name: 'By reapplied'},
  ],

  LICENSE_TYPE_LIST: [
    ...LICENSE_TYPE_OPTIONS
  ],

  async mounted() {
    await this.setDefaultPagination()
    await this.preSelectPageData()
    await this.loadList({})
  },

  methods: {
    async loadList({more = false}) {
      try {
        this.loading = true
        let {results, pagination, counts} = await this.applicationService.list(this.$route.query)
        if (more) this.list.push(...results)
        else this.list = results
        await this.$nextTick()
        this.counts = counts
        this.pagination = new PaginationModel(this.setPaginationParams(pagination))
      } catch (error) {
        console.log(error)
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async loadMore() {
      if (!this.pagination) return
      await this.onLoadMoreClick()
      await this.loadList({more: true})
    },

    async updateSorting(ordering) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ordering: ordering}})
      await this.loadList({})
    },

    updateItem(ID, field, data) {
      let itemIndex = this.list.findIndex(item => item.id === ID)
      if (itemIndex < 0) return
      this.list[itemIndex][field] = data
    },

    reset() {
      this.list = []
    },

    changeTabs() {
      this.reset()
      this.loadList({})
    },

    async setFilter(type, payload, load = true) {
      if (this.loading) return
      if (this.$route.query[type] == payload) return
      if (!payload || !payload.length) return await this.setQuery({[type]: undefined}, load)
      try {
        this.loading = true
        await this.setQuery({[type]: payload}, load);
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    resetFilter(type) {
      if (this.loading) return
      if (!this.$route.query[type]) return
      this.setFilter([type], undefined)
    },

    async setQuery(data, load) {
      await this.$router.replace({query: {...this.$route.query, ...this.getDefaultPagination(), ...data}})
      if (load) await this.loadList({})
    },

    async preSelectPageData() {
      if (!this.$route.query.tab) await this.setFilter('tab', TASK_APPLICATIONS_TAB.TASKS, false)
      if (this.$route.query[JUMBOTRON_FILTERS.COURSE_TYPE] !== 'offline') await this.setFilter([JUMBOTRON_FILTERS.COURSE_TYPE], 'offline', false)
      if(!this.$route.query[JUMBOTRON_FILTERS.IS_REAPPLY]) await this.setFilter([JUMBOTRON_FILTERS.IS_REAPPLY], 'false', false)
      
    }
  },

  provide: function () {
    return {
      updateItem: this.updateItem,
      applicationService: this.applicationService
    }
  },

  components: {
    appSelect,
    toolbarTitle: () => import('@/components/global/toolbar/ToolbarTitle.vue'),
    jumbotron: () => import('@/components/global/fields/Jumbotron.vue'),
    search: () => import('./components/TaskApplicationsSearch.vue'),
    list: () => import('./components/TaskApplicationsList.vue'),
    tabs: () => import('./components/TaskApplicationsTabs.vue'),
    pagination: () => import('@/components/widget/PaginationWidget.vue'),
  }
}
</script>