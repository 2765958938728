import { TASK_APPLICATIONS_TAB } from './taskApplications-const';

export default class taskApplicationsService {
  constructor(api) {
    this.api = api;
  }

  async list(query) {
    try {
      if (!('tab' in query)) query.tab = TASK_APPLICATIONS_TAB.TASKS;
      let res = await this.api.list(query);
      if (!res.results) throw new Error('Something wrong and candidates not loading. Please, reload page or try later.');
      let data = {
        results: res.results,
        pagination: {
          count: res.count,
          next: res.next,
          previous: res.previous
        },
        counts: res.counts
      };
      return data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async similarTasks(id) {
    try {
      let res = await this.api.similarTasks(id);
      if (!res.length) throw new Error('Something wrong and tasks not loading. Please, reload page or try later.');
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async addLog(id, data) {
    try {
      let _data = {...data};
      if (!_data.due_date) delete _data.due_date;
      let res = await this.api.addLog(id, _data);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async mergeTasks(ids) {
    try {
      let res = await this.api.mergeTasks(ids);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async saveNote(ID, data) {
    try {
      let res = await this.api.patch(ID, {notes: data});
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateDueDate(ID, data) {
    try {
      let res = await this.api.patch(ID, {due_date: data});
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async updateIsActive(ID, data) {
    try {
      let res = await this.api.patch(ID, {is_active: data});
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}
