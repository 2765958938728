import auth from '@/services/CRMinitAuth';

const list = query => {
  return auth.get(`${process.env.VUE_APP_CRM_API}application_task_list/`, {
    params: query
  });
};

const similarTasks = taskID => {
  return auth.get(`${process.env.VUE_APP_CRM_API}application_task_list/${taskID}/similar_tasks/`);
};

const patch = (ID, data) => {
  return auth.patch(`${process.env.VUE_APP_CRM_API}application_task_list/${ID}/`, data);
};

const mergeTasks = tasks => {
  let ids = tasks.map(task => task.id);
  return auth.post(`${process.env.VUE_APP_CRM_API}application_task_list/merge_tasks/`, {ids});
};

const addLog = (id, comment) => {
  return auth.post(`${process.env.VUE_APP_CRM_API}application_task_list/${id}/add_log/`, comment);
};

export default {
  list,
  patch,
  similarTasks,
  mergeTasks,
  addLog
};
