export const TASK_APPLICATIONS_HEADERS = [
  {text: 'Last Application Date', value: 'number', class: 'task-applications-number', align: 'left', sortable: false},
  {text: 'Name', value: 'name', class: 'task-applications-name', align: 'left', sortable: false},
  {text: 'Phone', value: 'dob', class: 'task-applications-dob', align: 'left', sortable: false},
  {text: 'Email', value: 'dob', class: 'task-applications-dob', align: 'left', sortable: false},
  {text: 'Number of Applications', value: 'applications_count', class: 'task-applications-count', align: 'left', sortable: false},
  {text: 'Due date', value: 'due_date', class: 'task-applications-due-date', align: 'left', sortable: true},
  {text: 'State', value: 'last_date', class: 'task-applications-state', align: 'left', sortable: false},
  {text: 'Note / State', value: 'notes_state', class: 'task-applications-notes', align: 'left', sortable: false}
];

export const TASK_SIMILAR_APPLICATIONS_HEADERS = [
  {text: 'Candidate №', value: 'number', class: 'task-applications-number', align: 'left', sortable: false},
  {text: 'Name', value: 'name', class: 'task-applications-name', align: 'left', sortable: false},
  {text: 'Date of Birth', value: 'dob', class: 'task-applications-dob', align: 'left', sortable: false},
  {text: 'Number of Applications', value: 'applications_count', class: 'task-applications-count', align: 'left', sortable: false},
  {text: 'Due date', value: 'due_date', class: 'task-applications-due-date', align: 'left', sortable: true},
  {text: 'Last Application Date', value: 'last_date', class: 'task-applications-last-date', align: 'left', sortable: false},
  {text: '', value: 'actions', class: 'task-applications-actions', align: 'left', sortable: false},
];

export const TASK_APPLICATIONS_TAB = {
  TASKS: 'tasks',
  WAIT: 'wait',
  FUTURE: 'future',
  CANCELED: 'canceled',
  PAID: 'paid'
};

export const TASK_APPLICATIONS_TYPES_LIST = {
  NO_PICK_UP: {label: 'Didn\'t pick up', value: 'NO_PICK_UP'},
  WILL_PAY: {label: 'Will pay', value: 'WILL_PAY'},
  CANCELED: {label: 'Canceled - after contact', value: 'CANCELED'},
  CANCELED_NO_CON: {label: 'Canceled - no contact', value: 'CANCELED_NO_CON'},
  CALL_BACK: {label: 'Call back', value: 'CALL_BACK'},
  PAID: {label: 'Paid', value: 'PAID'},
  OTHER: {label: 'Other', value: 'OTHER'}
};

export const JUMBOTRON_FILTERS = {
  COURSE_TYPE: 'course_type',
  EXAM_TYPE: 'is_mijn_reservation',
  IS_REAPPLY: 'is_reapply',
  LICENSE_TYPE: 'license_type',
};
